/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.app {
  text-align: center;
}

.app-content {
    margin-top: 2em;
}

.app-header {
    background-color: #252438; /*#57068c;*/
    height: 75px;
    text-align: left;
}

.app-notification {
    margin: 10em 5em;
}

.app-form {
    background: #fff;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    padding: 20px 20px 20px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.app-form-content {
    background: #ff0000;
    padding: 10px 20px 10px 20px;
}

.small-title {
    color: #ff0000;
    font-size: 1em;
    font-family: 'Oxanium', cursive;
    font-weight: 700;
    padding: 10px 0px 0px 20px;
    margin: 0px;
}

.large-title {
    color: #fff;
    font-size: 2em;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    padding: 00px 0px 0px 20px;
    margin: 0px;
}

.ui.form {
  width: 100%;
}

.ui.form .field > label {
  text-align: left;
}

.ui.navigation.menu {
  background-color: #57068c;
  height: 50px;
  width: 100%;
}

.ui.navigation.menu > img {
  height: 50px;
  padding: 3px;
}

div.nav {
    margin-top: 20px;
}

div.main-links {
    margin: 100px 0px;
}

/* Success form */

div.top-bottom-spaced {
    margin-top: 75px;
    margin-bottom: 75px;
}


/* Date picker */

.react-datepicker-wrapper {
  width: 100%;
}


/* Form headers */

div.subform-context-menu {
    padding: 5px 0px;
    text-align: right;
}

p.label {
    display: block;
    margin: 0 0 .28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
    text-align: left;
}


/* Buttons */

div.form-action-buttons {
    text-align: right;
}


/* Text */

.item.large-text > .text {
    font-size: 1.14285714rem;
}

.ui.checkbox.large-text {
    font-size: 1.14285714rem;
}
