/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

#description {
  height: 150px;
}


.fields.long > .field {
  padding: 0px 0px 0px 5px !important;
  width: 99%;
}


/* Upload file modal */

div.upload-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px 0px;
}

p.upload-text {
    margin-top: 20px;
}
