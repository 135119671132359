/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/* Searchable dropdown */

.dropdown.icon, .menu .field > .button {
    font-size: 1.14285714rem;
    height: 40px;
    width: 100%;
}

.ui.dropdown.icon.button {
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  border-radius: .28571429rem;
  color: rgba(0,0,0,.87);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 400;
}

.ui.dropdown.icon.button, .ui.dropdown.icon.button > .dropdown.icon {
    background-color: #fff;
}

#searchInput {
  max-width: 90% !important;
}

.ui.search.dropdown:hover {
  background-color: white;
}

.list-item {
    font-weight: bold;
    color: #111333;
}

/* Property table */

table.prop-table {
    border-bottom: solid 1px #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100%;
}

table.last-prop-table {
    width: 100%;
}

td.prop-label {
    font-weight: bold;
    text-align: left;
    vertical-align: top;
    width: 175px;
}

td.prop-text {
    text-align: left;
}


/* Text highlighting */

span.text-warning {
    color: #ff0000;
}
